:root {
    --twinkle-duration: 4s;
  }
  
  .stars-wrapper {
    position: absolute;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(#16161d, #1f1f3a, #3b2f4a);
    overflow: hidden;
    z-index: -999;
  }
  
  .stars {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .star {
    fill: white;
    animation: twinkle var(--twinkle-duration) ease-in-out infinite;
  
    &:nth-child(3n) {
      animation-delay: 1s; /* Adjust the delay as needed */
    }
    &:nth-child(7n) {
      animation-delay: 2s; /* Adjust the delay as needed */
    }
    &:nth-child(13n) {
      animation-delay: 3s; /* Adjust the delay as needed */
    }
    &:nth-child(19n) {
      animation-delay: 4s; /* Adjust the delay as needed */
    }
  }
  
  @keyframes twinkle {
    10%, 100% {
      opacity: 1;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
    75% {
      opacity: 0.2;
    }
  }
  
  .comet {
    transform-origin: center center;
    animation: comet-move 10s linear infinite; /* Adjust the duration as needed */
  }
  
  @keyframes comet-move {
    0% {
      transform: translateX(100%); /* Start from the right side of the screen */
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      transform: translateX(-100vmax); /* Move to the left side of the screen */
      opacity: 0;
    }
  }
  
  .comet-b {
    animation-delay: -3.3s; /* Adjust the delay for comet-b */
  }
  
  .comet-c {
    animation-delay: -5s; /* Adjust the delay for comet-c */
  }
  