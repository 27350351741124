
.cents{
    font-size: 1rem;
    vertical-align: top;
}


    @media (max-height: 600px) {
        h1 {
          font-size: 3rem!important;
        }
      }
