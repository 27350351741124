@import url(//fonts.googleapis.com/css?family=Vibur);
.flex{
    display: flex;
    width: 100%;
    flex-direction: row;
}

.logo {
  text-align: center;
  width: 100%;
  user-select: none;
}

.logo b{
  font: 400 5rem "Vibur";
  color: #dadeff;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #7244ff, 0 0 0.5em #5a44ff, 0 0 0.1em #5d44ff, 0 10px 3px #000;
}
.logo b span{
  animation: blink linear infinite 5s;
}
.logo b span:nth-of-type(2){
  animation: blink linear infinite 3s;
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}


/* Tablet devices (widths from 768px to 1024px) */
@media (max-width: 1024px) {
    .logo b {
      font-size: 4rem; /* Slightly smaller font size for tablets */
    }
  }
  
  /* Mobile devices (widths up to 767px) */
  @media (max-width: 767px) {
    .logo b {
      font-size: 2.5rem; /* Much smaller font size for mobile for better fit */
    }
  }